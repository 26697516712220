import React from 'react';
import { Form, Input, DatePicker, Select, Radio, Checkbox, Row, Col } from 'antd';

const CustomerContractForm = ({ customerRawData, selectedOption, onServiceTypeChange, disabled, editMode, initialValues }) => {
    const onCheckboxGroupChange = (checkedValues) => {
        const isTransportationSelected = checkedValues.includes("transportation");
        onServiceTypeChange(isTransportationSelected);
    };
    return (
        <>
            <h3 style={{ position: "relative" }}>Customer Contract</h3>
            <hr style={{ margin: "8px 0" }} />
            <Row gutter={{ xs: 8, sm: 16, md: 20, lg: 20, xl: 20, xxl: 20 }}>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        name="contract_name"
                        label="Contract Name"
                        rules={[{ required: true, message: "Contract Name is required", whitespace: true }]}
                        hasFeedback
                    >
                        <Input disabled={disabled}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        name="customer_id"
                        label="Customer"
                        rules={[{ required: true, message: "Customer is required" }]}
                        hasFeedback
                    >
                        <Select
                            showSearch
                            allowClear
                            options={customerRawData.customers?.map((customer) => ({
                                label: customer.company_name,
                                value: customer.id,
                            }))}
                            filterOption={(input, option) =>
                                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                            }
                            disabled={disabled}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        name="start_date"
                        label="Contract Start Date"
                        rules={[{ required: true, message: "Start date is required" }]}
                        hasFeedback
                    >
                        <DatePicker format="YYYY-MM-DD" allowClear style={{ width: "100%" }} disabled={disabled}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        name="end_date"
                        label="Contract End Date"
                        rules={[
                            {
                                required: true,
                                message: "End date is required"
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('start_date') <= value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('End date must be a date on or after start_date');
                                },
                            }),
                        ]}
                        hasFeedback
                    >
                        <DatePicker format="YYYY-MM-DD" allowClear style={{ width: "100%" }} disabled={disabled}/>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        name="status"
                        label="Status"
                        rules={[{ required: true, message: 'Please select a status!' }]}
                    >
                        <Radio.Group disabled={disabled || initialValues === null}>
                            <Radio value="Draft">Draft</Radio>
                            <Radio value="Active">Active</Radio>
                            <Radio value="Expired">Expired</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        name="billing_type"
                        label="Billing Type"
                        rules={[{ required: true, message: 'Please select a billing type!' }]}
                    >
                        <Radio.Group disabled={disabled}>
                            <Radio value="Single Invoice">Single Invoice</Radio>
                            <Radio value="Multi Invoice">Multi Invoice</Radio>
                            <Radio value="No Invoice">No Invoice</Radio>
                            <Radio value="Monthly Fixed Amount">Monthly Fixed Amount</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                        name="service_types"
                        label="Service Types"
                        rules={[{ required: true, message: 'Please select at least one service type!' }]}
                    >
                        <Checkbox.Group onChange={onCheckboxGroupChange} disabled={disabled}>
                            <Form.Item>
                                <Checkbox value="transportation">Transportation</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox value="custom clearance">Custom Clearance</Checkbox>
                            </Form.Item>
                            <Form.Item>
                                <Checkbox value="storage">Storage</Checkbox>
                            </Form.Item>
                        </Checkbox.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item name="id" style={{ display: 'none' }}>
        <Input />
    </Form.Item>
    {/* Add any other form items here */}
</>
    );
};

export default CustomerContractForm;
