const JobOrderStatus = {
    DRAFT: 'Draft',
    WITH_DISPATCHER: 'With Dispatcher',
    WAITING_FOR_DRIVER_ACCEPTANCE: 'Waiting For Driver Acceptance',
    WAYBILL_GENERATED: 'WayBill Generated',
    IN_TRANSIT: 'In Transit',
    POD_REQUESTED: 'POD Requested',
    DELIVERED: 'Delivered',
    INVOICE_ISSUED: 'Invoice Issued'
};

export default JobOrderStatus;
