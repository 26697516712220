import React, { useEffect, useState } from 'react';
import { Button, Card, Divider, Tag, Typography, Input, Select, Form } from 'antd';
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined, TruckFilled, UserOutlined } from '@ant-design/icons';
import { BuildingIcon } from '../../../../../../components/CustomIcons';
import { updateFleet } from '../../../../../../network/network';
import NotificationWithIcon from '../../../../../../components/NotificationWithIcon';
import ImageUpload from '../../../../../../components/ImageUpload';
const { Text } = Typography;
const { Option } = Select;

const FleetProfile = ({ fleet, cardStyle, rawFleetData, onFleetUpdate, onRawDataUpdate }) => {
    const [editForm] = Form.useForm();
    const [editMode, setEditMode] = useState(false);
    const [image, setImage] = useState(false);
    const [fleetData, setFleetData] = useState({
        ...fleet,
    });

    useEffect(() => {
        setFleetData({
            ...fleet,
        });
    }, [fleet])

    const handleEdit = () => {
        setEditMode(true);
        setImage(fleetData.image);
        editForm.setFieldsValue({
            id: fleetData.id,
            license_plate: fleetData.license_plate,
            fleet_make_id: fleetData.make.id,
            fleet_type_id: fleetData.fleet_type.id,
            status_id: fleetData.statuses.id,
            tags: fleetData.tags,
            hub_id: fleetData.hub.id,
            stage: 'On Route',
        });
    };

    const handleCancel = () => {
        editForm.resetFields();
        setEditMode(false);
    };


    const handleAPIResponse = (response) => {
        if (response.success === true) {
            NotificationWithIcon("success", `Fleet updated successfully`, `Fleet updated Successfully`);
            editForm.resetFields();
            onRawDataUpdate();
            onFleetUpdate();
        } else {
            response.data.errors.forEach(function (key, value) {
                let temp = Object.values(key);
                NotificationWithIcon("error", "Something Went Wrong", temp[0]);
            });
        }
    };

    const handleSave = async () => {
        setEditMode(false);
        try {
            const values = await editForm.validateFields();
            const formData = new window.FormData();
            Object.keys(values).forEach(key => {
                if (values[key] !== undefined && !Array.isArray(values[key])) {
                    formData.append(key, values[key]);
                } else if (Array.isArray(values[key])) {
                    values[key].forEach((tag, index) => {
                        formData.append(`${key}[${index}]`, tag);
                    });
                }
            });
            if (image && image instanceof File) {
                formData.append("image", image);
            }
            const response = await updateFleet(formData, fleet.id);
            handleAPIResponse(response.data);
        } catch (error) {
            console.error('Failed to save data', error);
            // Handle errors (e.g., show an error message)
        }
    };

    const handleRemoveTag = (index) => {
        const newTags = [...fleetData.tags];
        newTags.splice(index, 1);
        setFleetData({ ...fleetData, tags: newTags });
    };

    return (
        <Card style={{ width: '100%', overflow: 'hidden', ...cardStyle }}>
            <Form form={editForm} onFinish={handleSave} layout="vertical">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Image Column */}
                        <div style={{ marginRight: '15px' }}>
                            {editMode ? (
                                <ImageUpload
                                    name="fleet_image"
                                    callbackSetImageFile={setImage}
                                    image={image}
                                />
                            ) : (fleetData?.image ? (
                                <img
                                    src={fleetData?.image}
                                    alt="fleet pictuer"
                                    style={{ width: '80px', height: '80px', borderRadius: '50%', border: '2px solid #f0f0f0' }}
                                />
                            ) : (
                                <TruckFilled style={{ fontSize: '80px' }} />
                            ))}
                        </div>
                        <div>
                            <div>
                                {editMode ? (
                                    <Form.Item name={'fleet_make_id'} rules={[{ required: true, message: 'Please select the fleet make' }]}>
                                    <Select style={{ width: 120, marginRight: '5px' }}>
                                        {rawFleetData.fleet_makes.map((make) => (
                                            <Option key={make.id} value={make.id}>{make.title}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                                ) : (
                                    <Text>{fleetData?.make?.title}</Text>
                                )}
                            </div>
                            <div>
                                {editMode ? (
                                    <Form.Item name={'license_plate'} label="License Plate No." rules={[{ required: true, message: 'Please input the the license plate' }]}>
                                        <Input />
                                    </Form.Item>
                                ) : (
                                    <Text>{fleetData?.license_plate}</Text>
                                )}
                            </div>
                        </div>
                    </div>
                    <div>
                        {editMode ? (
                            <>
                                <div><Button onClick={handleSave} type="primary" style={{ marginRight: '10px' }}>
                                    <SaveOutlined /> Save
                                </Button>
                                    <Button onClick={handleCancel}>
                                        <CloseOutlined /> Cancel
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <Button onClick={handleEdit} type="primary">
                                <EditOutlined /> Edit Fleet
                            </Button>
                        )}
                    </div>
                </div>
                <Divider style={{ margin: '10px 0' }} />
                {/* Lower Section for Additional Data */}
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px 10px 10px' }}>
                    {/* <Text>Fleet Type: {editMode ? <Input value={fleetData.type} onChange={(e) => setFleetData({ ...fleetData, type: e.target.value })} /> : fleetData.type}</Text> */}


                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0 10px 10px 10px' }}>
                    <Text>Stage <br />
                                {editMode ? (
                                    <Form.Item name={'stage'} label="Stage" rules={[{ required: true, message: 'Please input the the stage' }]}>
                                        <Input />
                                    </Form.Item>
                                ) : (
                                    <Tag bordered={false} color='green' icon={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" width="16" height="16">
                                    <path d="M21.81,34.75H21.5A3.8,3.8,0,0,1,18,31.58L16,20.12A1.36,1.36,0,0,0,14.88,19L3.42,17a3.84,3.84,0,0,1-.56-7.42L29.66.46h0a3.84,3.84,0,0,1,4.88,4.88l-9.11,26.8A3.79,3.79,0,0,1,21.81,34.75ZM30.47,2.83,3.66,11.94a1.34,1.34,0,0,0,.2,2.59l11.46,2a3.85,3.85,0,0,1,3.11,3.11l2,11.46a1.34,1.34,0,0,0,2.59.2L32.17,4.53a1.34,1.34,0,0,0-1.7-1.7Z" fill="green" />
                                </svg>} key={'On Route'}>{'On Route'}</Tag>
                                )}
                            </Text>
                    <Text>Fleet Type <br /> {editMode ?
                        <Form.Item name={'fleet_type_id'} rules={[{ required: true, message: 'Please select a type' }]}><Select style={{ width: 120, marginRight: '5px' }}>
                        {rawFleetData.fleet_types.map((type) => (
                            <Option key={type.id} value={type.id}>{type.name}</Option>
                        ))}
                    </Select>
                        </Form.Item>
                        : <Tag bordered={false} color='blue'>{fleetData?.fleet_type?.name}</Tag>}</Text>
                    <div style={{ width: '50%' }}>
                        Tags <br /> {editMode ? (
                            <>
                                <Form.Item name={'tags'} rules={[{ required: true, message: 'Please select or add tags' }]}>
                                    <Select
                                        mode="tags"
                                        style={{ width: '100%' }}
                                        placeholder="Select or add tags"
                                        onChange={(value) => setFleetData({ ...fleetData, tags: value })}
                                        value={fleetData.tags}
                                    >
                                        {rawFleetData.tags.map((tag) => (
                                            <Option key={tag.id} value={tag.id}>{tag.name}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </>
                        ) : fleetData?.tags?.map(tagId => <Tag key={tagId}>{rawFleetData.tags?.find(tag => tag.id === tagId)?.name}</Tag>)}
                    </div>
                    <Text>Hub <br /> {editMode ?
                        <Form.Item name={'hub_id'} rules={[{ required: true, message: 'Please select a hub' }]}><Select style={{ width: 120, marginRight: '5px' }}>
                        {rawFleetData.hubs.map((hub) => (
                            <Option key={hub.id} value={hub.id}>{hub.name}</Option>
                        ))}
                    </Select>
                        </Form.Item>
                        : <Tag bordered={false} color='blue'>{fleetData?.hub?.name}</Tag>}</Text>
                        </div>
                    <div>
                        Status: {editMode ? (
                            <Form.Item name={'status_id'} rules={[{ required: true, message: 'Please select a status' }]}>
                                <Select style={{ width: 120 }}>
                                {rawFleetData.statuses.map((status) => (
                            <Option key={status.id} value={status.id}>{status.name}</Option>
                        ))}
                                </Select>
                            </Form.Item>
                        ) : (
                            <Tag color={fleetData?.status === 'Active' ? 'green' : 'volcano'}>{fleetData?.statuses?.name}</Tag>
                        )}
                    </div>
                </div>
            </Form>
        </Card>
    );
};

export default FleetProfile;
