import React, { useEffect, useState } from "react";
import { Card, Button, Input, Form, Row, Col, Upload, Table } from "antd";
import { DownloadOutlined, MinusCircleOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { PageHeader } from "@ant-design/pro-layout";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);


const Documents = ({ documents, setDocuments, documentsData = [], mode }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({ documents });
    }, [documents, form]);

    const addDocument = () => {
        setDocuments([...documents, { title: null, document: null, expiry_date: null }]);
    };

    const removeDocument = (index) => {
        const updatedDocuments = [...documents];
        updatedDocuments.splice(index, 1);
        setDocuments(updatedDocuments);
    };

    const handleFormChange = (changedValues, allValues) => {
        setDocuments(allValues.documents);
    };

    const tableColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return index + 1;
            },
        },
        {
            title: "Title",
            dataIndex: "title",
        },
        {
            title: "Action",
            align: "center",
            width: "140px",
            render: (record) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            onClick={() => {
                                window.open(record.url, '_blank');
                            }}
                            title="Download Document"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(82, 196, 26, 0.2)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <DownloadOutlined style={{ color: "#52c41a" }} />
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Card style={{ marginBottom: '20px' }}>
                {mode !== "View" && (
                    <div>
                        <Form
                            form={form}
                            layout="vertical"
                            onValuesChange={handleFormChange}
                        >
                            {documents.map((doc, index) => (
                                <Row key={index} gutter={16} style={{ marginBottom: 8 }}>
                                    <Col span={6}>
                                        <Form.Item
                                            name={['documents', index, 'title']}
                                            label="Title"
                                            rules={[{ required: true, message: 'Please input the title' }]}
                                        >
                                            <Input placeholder="Enter Title" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            name={['documents', index, 'document']}
                                            label="Document"
                                            rules={[{ required: true, message: 'Please select the Document' }]}
                                        >
                                            <Upload beforeUpload={() => false} showUploadList={true} maxCount={1}>
                                                <Button icon={<UploadOutlined />}>Select File</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} className="flex items-end justify-center">
                                        <Button
                                            type="link"
                                            danger
                                            onClick={() => removeDocument(index)}
                                            icon={<MinusCircleOutlined />}
                                        >
                                            Remove
                                        </Button>
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={addDocument}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Add Document
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                )}
                {documentsData.length > 0 && (
                    <Table columns={tableColumns} dataSource={documentsData} rowKey="id" bordered />
                )}
            </Card>
        </>
    );
};

export default Documents;
