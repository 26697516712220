import React, { useState, useEffect } from "react";
import { Card, Form, Button, Table, Drawer, Row, Space, Tabs, message, Popconfirm, Input, Tag } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import { addCustomerContract, getCustomerContracts, updateCustomerContract, rawDataCustomerContract, deleteCustomerContract, duplicateCustomerContract } from "../../../../network/network";
import NotificationWithIcon from "../../../../components/NotificationWithIcon";
import CustomerContractForm from "./CustomerContractForm";
import Mate from '../../../../components/Mate/Mate';
import TransportationForm from "./TransportationForm";
import { ContactsOutlined, CopyOutlined, DeleteTwoTone, EditTwoTone, EyeFilled, EyeInvisibleTwoTone, TruckFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { debounce } from "lodash";
import { useParams } from "react-router-dom";
dayjs.extend(utc);


const CustomerContract = () => {
    const { contractId } = useParams();
    const [loading, setLoading] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [contractsAllData, setContractsAllData] = useState([]);
    const [contractsRawData, setContractsRawData] = useState([]);
    const [pagination, setPagination] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [activeTabKey, setActiveTabKey] = useState('1');
    const [isTransportationSelected, setIsTransportationSelected] = useState(false);
    const [selectedContract, setSelectedContract] = useState(null);
    const [operationMode, setOperationMode] = useState('');
    const [initialValues, setInitialValues] = useState({});
    const [contractsForm] = Form.useForm();

    const fetchData = async () => {
        try {
            if (contractsRawData.length === 0) {
                const rawDataResponse = await rawDataCustomerContract();
                setContractsRawData(rawDataResponse.data.data);
            }
            const contractsResponse = await getCustomerContracts({
                page: currentPage,
                search: searchTerm
            });
            setContractsAllData(contractsResponse.data.data.customerContracts);
            const paginationData = contractsResponse.data.data.pagination;
            setPagination({
                current: paginationData.current_page,
                pageSize: paginationData.per_page,
                total: paginationData.total,
                showSizeChanger: false,
            });
        } catch (error) {
            console.error("Fetching data failed:", error);
        } finally {
            setIsDataLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    useEffect(() => {
        if (contractId) {
            const contractToShow = contractsAllData.find(contract => contract.id === parseInt(contractId));
            if (contractToShow) {
                showContractDetailsDrawer(contractToShow);
            }
        }
    }, [contractId, contractsAllData]);

    const debouncedFetchData = debounce(() => {
        fetchData();
    }, 500);

    useEffect(() => {
        debouncedFetchData();
        // Cleanup to cancel the debounced call if component unmounts or the value changes
        return () => debouncedFetchData.cancel();
    }, [searchTerm]);

    useEffect(() => {
        if (operationMode === 'Edit' && initialValues.transportations && initialValues.transportations.length > 0) {
            handleServiceTypeChange(true);
        } else if (operationMode === 'Add') {
            handleServiceTypeChange(false);
        }
    }, [initialValues.transportations]);

    const handleCancel = () => {
        setDrawerVisible(false);
        setActiveTabKey('1');
        contractsForm.resetFields();
    };

    const handleAPIResponse = (response, isAdding) => {
        if (response.success === true) {
            NotificationWithIcon("success", "Customer Contract Deleted", `Customer Contract ${isAdding ? 'Added' : 'Updated'} Successfully`);
            fetchData();
        } else {
            response.data.errors.forEach(function (key, value) {
                let temp = Object.values(key);
                NotificationWithIcon("error", "Something Went Wrong", temp[0]);
            });
        }
    };

    const handleNext = () => {
        // Simply move to the next tab without submitting the form or making an API call
        setActiveTabKey(String(Number(activeTabKey) + 1));
    };

    const handleServiceTypeChange = (checked) => {
        setIsTransportationSelected(checked);
        // Reset to first tab if Transportation is unchecked
        if (!checked) {
            setActiveTabKey('1');
        }
    };

    const handleAddEdit = async () => {
        try {
            setLoading(true);
            const values = await contractsForm.validateFields();
            const contractObj = {
                ...values,
                start_date: dayjs.utc(values.start_date).local().format('YYYY-MM-DD'),
                end_date: dayjs.utc(values.end_date).local().format('YYYY-MM-DD')
            };
            let action;
            if (operationMode === 'Add') {
                action = addCustomerContract;
            } else {
                action = updateCustomerContract;
            }
            const response = await action(contractObj);
            handleAPIResponse(response.data, operationMode === 'Add');
            handleCancel();
            setIsTransportationSelected(false);
        } catch (error) {
            console.error("Validation or API call failed:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = (id) => {
        deleteCustomerContract(id)
            .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                    NotificationWithIcon("success", "Customer Contract Deleted", data.message);
                    fetchData();
                } else {
                    // Error
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                // Error
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    const handleDuplicate = (id) => {
        duplicateCustomerContract(id)
            .then((response) => {
                let data = response.data || response.response.data;
                if (data.success === true) {
                    NotificationWithIcon("success", "Customer Contract Duplicated", data.message);
                    fetchData();
                } else {
                    // Error
                    NotificationWithIcon("error", "Something Went Wrong", data.message);
                }
            })
            .catch((info) => {
                // Error
                NotificationWithIcon(
                    "error",
                    "Something Went Wrong",
                    info.response.data.message
                );
            });
    };

    const showModal = (mode, contract = null) => {
        setSelectedContract(contract);
        setOperationMode(mode);
        let initialValues;
        if (mode === 'Add') {
            setIsTransportationSelected(false);
            initialValues = {
                id: null,
                contract_name: "",
                customer_id: 1,
                start_date: null,
                end_date: null,
                billing_type: null,
                service_types: [],
                status: "Draft",
                transportations: [
                    {
                        from_location_id: null,
                        to_location_id: null,
                        charges: null,
                        transportation_mode: "Internal",
                        load_type_id: null,
                        fleet_type_id: null
                    },
                ]
            };
        } else {
            setIsTransportationSelected(contract.transportations.length !== 0);
            initialValues = {
                ...contract,
                customer_id: contract.customer.id,
                start_date: dayjs(contract.start_date, "YYYY-MM-DD"),
                end_date: dayjs(contract.end_date, "YYYY-MM-DD"),
                transportations: contract.transportations.map(transportation => ({
                    from_location_id: transportation.from_location.id,
                    to_location_id: transportation.to_location.id,
                    charges: transportation.charges,
                    transportation_mode: 'Internal',
                    load_type_id: transportation.load_type.id,
                    fleet_type_id: transportation.fleet_type.id
                }))
            };
        }
        setInitialValues(initialValues);
        contractsForm.setFieldsValue(initialValues);
        setDrawerVisible(true);
    };

    const showContractDetailsDrawer = (contract) => {
        setOperationMode('View');
        setSelectedContract(contract);
        setIsTransportationSelected(contract.transportations.length !== 0);
        const initialValues = {
            ...contract,
            customer_id: contract.customer.id,
            start_date: dayjs(contract.start_date, "YYYY-MM-DD"),
            end_date: dayjs(contract.end_date, "YYYY-MM-DD"),
            transportations: contract.transportations.map(transportation => ({
                from_location_id: transportation.from_location.id,
                to_location_id: transportation.to_location.id,
                charges: transportation.charges,
                transportation_mode: 'Internal',
                load_type_id: transportation.load_type.id,
                fleet_type_id: transportation.fleet_type.id
            }))
        };
        setInitialValues(initialValues);
        contractsForm.setFieldsValue(initialValues); // Explicitly set form values
        setDrawerVisible(true);
    };

    const handleContractsTableChange = (pagination, filters, sorter) => {
        setPagination(pagination);
        setCurrentPage(pagination.current);
    };

    const tabItems = [
        {
            label: <><ContactsOutlined /> Contract Info </>,
            key: '1',
            children: (
                <CustomerContractForm
                    customerRawData={contractsRawData}
                    onServiceTypeChange={handleServiceTypeChange}
                    disabled={operationMode === 'View'}
                    editMode={operationMode === 'Edit'}
                    initialValues={selectedContract}
                />
            ),
        },
        // Include the Transportation tab conditionally
    ...(isTransportationSelected ? [{
        label: <><TruckFilled /> Transportations </>,
        key: '2',
        children: (
            <TransportationForm
                customerRawData={contractsRawData}
                disabled={operationMode === 'View'}
                editMode={operationMode === 'Edit'}
                initialValues={selectedContract?.transportations}
            />
        ),
    }] : []),
    {
        label: '👋 Mate',
        key: isTransportationSelected ? '3' : '2',
        children: <Mate entityType="CustomerContract" entityId={selectedContract?.id} />,
    },
    ];

    const isFirstTab = activeTabKey === tabItems[0].key;
    const isLastTab = activeTabKey === tabItems[tabItems.length - 1].key;
    const isSecondLastTab = activeTabKey === tabItems[tabItems.length - 2].key;


    const getStatusColor = (statusName) => {
        switch (statusName?.toLowerCase()) {
            case 'draft':
                return 'gold';
            case 'active':
                return 'success';
            case 'expired':
                return 'processing';
            default:
                return 'cyan';
        }
    };

    const tableColumns = [
        {
            title: "#",
            key: "index",
            width: "5%",
            render: (text, record, index) => {
                return ((pagination.current - 1) * pagination.pageSize) + index + 1;
            },
        },
        {
            title: "Contract Name",
            dataIndex: "contract_name",
        },
        {
            title: "Customer",
            render: (record) => (
                <>{record.customer ? record.customer.company_name : "-"}</>
            ),
        },
        {
            title: "Start Date",
            dataIndex: "start_date",
        },
        {
            title: "End Date",
            dataIndex: "end_date",
        },
        {
            title: "Billing Type",
            dataIndex: "billing_type",
        },
        {
            title: 'Status',
            key: 'statusColor',
            width: '5px',
            render: (record) => {
                return (
                    <Tag bordered={false} color={getStatusColor(record.status)}>{record.status}</Tag>
                );
            },
        },
        {
            title: "Action",
            align: "center",
            width: "170px",
            render: (record) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            type="link"
                            onClick={() => {
                                showContractDetailsDrawer(record);
                            }}
                            title="View Details"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(82, 196, 26, 0.2)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <EyeFilled style={{ color: "#52c41a" }} />
                        </Button>
                        <Button
                            type="link"
                            onClick={() => {
                                showModal('Edit', record);
                            }}
                            title="Edit record"
                            style={{
                                padding: 6,
                                fontSize: "18px",
                                borderRadius: "5px",
                                backgroundColor: "rgba(24, 144, 255, 0.2)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <EditTwoTone twoToneColor="#1890ff" />
                        </Button>
                        <Popconfirm
                            title="Are you sure to duplicate this entity? All related data will be duplicated."
                            onConfirm={() => handleDuplicate(record.id)}
                            okText="Yes"
                            cancelText="No"
                            disabled={record.status !== "Expired"}
                        >
                            <CopyOutlined
                                style={{
                                    padding: 6,
                                    fontSize: "18px",
                                    borderRadius: "5px",
                                    backgroundColor: "#f0f0f0",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                twoToneColor="#1890ff"
                                title="Duplicate Entity"
                            />
                        </Popconfirm>
                        <Popconfirm
                            title="Are you sure to delete this entity? All related data will be deleted."
                            onConfirm={() => handleDelete(record.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteTwoTone
                                style={{
                                    padding: 6,
                                    fontSize: "18px",
                                    borderRadius: "5px",
                                    backgroundColor: "rgba(255, 0, 0, 0.2)",
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                twoToneColor="#ff0000"
                                title="Delete Entity"
                            />
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <Card style={{ marginBottom: "20px" }}>
                <PageHeader
                    style={{ padding: "0" }}
                    title="Customer Contract"
                    ghost={false}
                    extra={[
                        <Button type="primary" onClick={() => { showModal('Add') }} key="add-customer-contract">
                            + Add Customer Contract
                        </Button>
                    ]}
                />
            </Card>
            <Card>
                <Input
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    onPressEnter={() => fetchData()}
                    style={{ marginBottom: 20, width: '50%' }}
                />
                <Table columns={tableColumns} dataSource={contractsAllData} loading={isDataLoading} rowKey="id" pagination={pagination} onChange={handleContractsTableChange} bordered />
            </Card>
            <Drawer
                title={`${operationMode} Customer Contract`}
                placement="right"
                onClose={handleCancel}
                open={drawerVisible}
                width={"calc(100% - 200px)"}
                footer={
                    <Row justify="end">
                        <Space>
                            <>
                                {!isFirstTab && !isLastTab && (
                                    <Button onClick={() => setActiveTabKey((parseInt(activeTabKey) - 1).toString())}>
                                        Back
                                    </Button>
                                )}
                                {!isLastTab && !isSecondLastTab && (
                                    <Button type="primary" onClick={handleNext}>
                                        Next
                                    </Button>
                                )}
                                <Button onClick={handleCancel}>Close</Button>
                                {operationMode !== 'View' && !isLastTab && (
                                    isSecondLastTab && (
                                        <Button type="primary" loading={loading} onClick={handleAddEdit}>
                                            {selectedContract ? 'Update' : 'Add'} Customer Contract
                                        </Button>
                                    )
                                )}

                            </>

                        </Space>
                    </Row>
                }
            >
                <Form
                    name="customer-contract-form"
                    onFinish={handleAddEdit}
                    layout="vertical"
                    form={contractsForm}
                    initialValues={initialValues}
                >
                    <Tabs activeKey={activeTabKey} onChange={setActiveTabKey} items={tabItems} />
                </Form>
            </Drawer>
        </>
    );
};

export default CustomerContract;
