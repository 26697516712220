import './Fleet.css';
import React, { useEffect, useState } from 'react';
import { Button, Card, DatePicker, Form, Input, InputNumber, Select, Table, Typography } from 'antd';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import NotificationWithIcon from '../../../../../../components/NotificationWithIcon';
import { updateFleet } from '../../../../../../network/network';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
const { Text } = Typography;
const { Option } = Select;

const FleetDetailsTable = ({ fleet, rawFleetData, cardStyle }) => {
    const [editForm] = Form.useForm();
    const [editMode, setEditMode] = useState(false);
    const [fleetData, setFleetData] = useState({
        ...fleet,
    });

    useEffect(() => {
        setFleetData({
            ...fleet,
        });
    }, [fleet])

    const handleEdit = () => {
        setEditMode(true);
        editForm.setFieldsValue({
            id: fleetData.id,
            category_id: fleetData.category?.id,
            color: fleetData.color,
            driver_id: fleetData.driver?.id,
            immatriculation_date: fleetData.immatriculation_date ? dayjs(fleetData.immatriculation_date, "YYYY-MM-DD") : null,
            model: fleetData.model,
            odometer: fleetData.odometer,
            vin: fleetData.vin,
            year: Number(fleetData.year),
        });
    };

    const handleCancel = () => {
        editForm.resetFields();
        setEditMode(false);
    };


    const handleAPIResponse = (response) => {
        if (response.success === true) {
            NotificationWithIcon("success", `Fleet updated successfully`, `Fleet updated Successfully`);
            editForm.resetFields();
            setFleetData({ ...response.data });
        } else {
            response.data.errors.forEach(function (key, value) {
                let temp = Object.values(key);
                NotificationWithIcon("error", "Something Went Wrong", temp[0]);
            });
        }
    };

    const handleSave = async () => {
        setEditMode(false);
        try {
            const data = await editForm.validateFields();
            const values = {
                ...data,
                immatriculation_date: data.immatriculation_date ? dayjs.utc(data.immatriculation_date).local().format('YYYY-MM-DD') : null,
            };
            const response = await updateFleet(values, fleet.id);
            handleAPIResponse(response.data);
        } catch (error) {
            console.error('Failed to save data', error);
            // Handle errors (e.g., show an error message)
        }
    };

    const Field = ({ editMode, fieldFormName, fieldType, fieldName, fieldValue, options }) => (
        <div style={{ display: 'flex', padding: '0', width: '100%' }}>
            <div style={{ padding: '5px 10px', width: '50%', border: '1px solid #f0f0f0', borderLeft: '0', textAlign: 'right' }}>{fieldName}</div>
            <div style={{ padding: '5px 10px', width: '50%', border: '1px solid #f0f0f0', borderLeft: '0', borderRight: '0' }}>{editMode ? (
                <Form.Item name={fieldFormName} rules={[{ required: true, message: `Please provide ${fieldName}` }]}>
                    {fieldType === 'select' ? (
                        <Select style={{ width: 120, marginRight: '5px' }}>
                            {options.map((option) => (
                                <Option key={option.id} value={option.id}>{option.name}</Option>
                            ))}
                        </Select>
                    ) : fieldType === 'datepicker' ? (
                        <DatePicker
                                    format="YYYY-MM-DD"
                                    style={{
                                        width: "100%",
                                    }}
                                />
                    ) : fieldType === 'year' ? (
                        <InputNumber />
                    ) : (
                        <Input />
                    )}
                </Form.Item>
            ) : (
                <Text>{fieldValue}</Text>
            )}
            </div>
        </div>
    );

    return (
        <div style={{ width: '40%', ...cardStyle, marginRight: '5px' }}>
            {editMode ? (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                    <div><Button onClick={handleSave} type="primary" style={{ marginRight: '10px' }}>
                        <SaveOutlined /> Save
                    </Button>
                        <Button onClick={handleCancel}>
                            <CloseOutlined /> Cancel
                        </Button>
                    </div>
                </div>
            ) : (

                <EditOutlined onClick={handleEdit} style={{ float: 'right', cursor: 'pointer', display: 'block', marginBottom: '10px' }} />
            )}
            {/* <Table dataSource={fleetDetails} pagination={false} columns={[
                { title: '', dataIndex: 'property', key: 'property' },
                { title: '', dataIndex: 'value', key: 'value' },
            ]} bordered showHeader={false} />
            <br /> */}
            <div>
                <Form form={editForm} onFinish={handleSave} layout="vertical">
                    <Field editMode={editMode} fieldFormName={'category_id'} fieldType="select" fieldName="Category" fieldValue={fleetData?.category?.name} options={rawFleetData.categories} />
                    <Field editMode={editMode} fieldFormName={'color'} fieldType="input" fieldName="Color" fieldValue={fleetData?.color} />
                    <Field editMode={editMode} fieldFormName={'driver_id'} fieldType="select" fieldName="Driver" fieldValue={fleetData?.driver?.name} options={rawFleetData.drivers} />
                    <Field editMode={editMode} fieldFormName={'immatriculation_date'} fieldType="datepicker" fieldName="Immatriculation Date" fieldValue={fleetData?.immatriculation_date} />
                    <Field editMode={editMode} fieldFormName={'model'} fieldType="input" fieldName="Model" fieldValue={fleetData?.model} />
                    <Field editMode={editMode} fieldFormName={'odometer'} fieldType="input" fieldName="Odometer" fieldValue={fleetData?.odometer} />
                    <Field editMode={editMode} fieldFormName={'vin'} fieldType="input" fieldName="Vin" fieldValue={fleetData?.vin} />
                    <Field editMode={editMode} fieldFormName={'year'} fieldType="year" fieldName="Year" fieldValue={fleetData?.year} />
                    {/* <div style={{ display: 'flex', padding: '0', width: '100%' }}>
                        <div style={{ padding: '5px 10px', width: '50%', border: '1px solid #f0f0f0', borderLeft: '0', textAlign: 'right' }}>Category</div>
                        <div style={{ padding: '5px 10px', width: '50%', border: '1px solid #f0f0f0', borderLeft: '0', borderRight: '0' }}>{editMode ? (
                            <Form.Item name={'category_id'} rules={[{ required: true, message: 'Please select the fleet category' }]}>
                            <Select style={{ width: 120, marginRight: '5px' }}>
                                {rawFleetData.categories.map((make) => (
                                    <Option key={make.id} value={make.id}>{make.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                        ) : (
                            <Text>{fleetData?.category?.name}</Text>
                        )}
                        </div>
                    </div>
                    <div style={{ display: 'flex', padding: '0', width: '100%' }}>
                        <div style={{ padding: '5px 10px', width: '50%', border: '1px solid #f0f0f0', borderLeft: '0', textAlign: 'right' }}>Vin</div>
                        <div style={{ padding: '5px 10px', width: '50%', border: '1px solid #f0f0f0', borderLeft: '0', borderRight: '0' }}>{editMode ? (
                            <Form.Item name={'vin'} rules={[{ required: true, message: 'Please type vin' }]}>
                                <Input />
                            </Form.Item>
                        ) : (
                            <Text>{fleetData?.vin}</Text>
                        )}
                        </div>
                    </div> */}
                </Form>
            </div>

        </div>
    );
};

export default FleetDetailsTable;
